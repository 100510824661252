.icon_links {
	background-color: $icon-links-bg-color;
	text-align: center;
	padding: 4em 1em;
	box-shadow: 0 0 40px lightgray;

	h2.element-type-heading{
		color: $icon-links-title-color;
		font-size: rem(32px);
		margin-bottom: 2rem;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.list__item {
			margin: .5rem 1em;
		}

		.list__link {
			display: flex;
			align-items: center;
			font-weight: 700;
			flex-direction: column;
			figure{
				margin: 0;
			}
			img,
			svg{
				margin-bottom: .5em;
				fill: $icon-links-ico-color;
				max-width: 86px;
			}
		}

		.list__icon {
			margin-bottom: 0;
		}
		.list__description{
			margin-bottom: 0;
		}

		@include link(".list__link") {
			color: $icon-links-color;
		}

		@include link-over(".list__link") {
			text-decoration: none;
			color: $icon-links-color-hover;
			svg{
				fill: $icon-links-ico-color-hover;
			}
		}
	}

	@include min(1024px) {
		position: absolute;
		top: 5vw;
		right: 0;
		min-height: calc(100% - 5vw);
		width: 225px;

		.list {
			flex-direction: column;
		}
	}

	@include min(1280px) {
		width: 270px;
	}
}
