.header{
    position: relative;
    &__container{
        flex-wrap: wrap;
        @include min(600px){
            flex-wrap: nowrap;
        }
    }
    &__heading {
        @include min(600px){
            padding-top: .4em;
            padding-bottom: 2.4em;
            &__container{
                padding-right: 3vw;
            }
        }
    }
    @include min(1024px){
        &__content{
            padding-bottom: 150px;
        }
        &__guide__container{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    &__content{
        display: flex;
    }
    .blender__container{
        padding-bottom: 480px;
        @include min(1480px){
            padding-bottom: 42.5%;
        }
    }
    &__map{
        &__container{
            position: relative;
            margin: 0 auto;
            order: 5;
            @include min(600px){
                position: absolute;
                left: 0;
                bottom: -120px;
                z-index: 30;
                max-width: 465px;
            }
            @include min(1024px){
                bottom: 20px;
            }
            @include min(1480px){
                max-width: 40%;
            }
        }
        &__marker{
            position: absolute;
            width: 8.2075%;
            top: 2%;
            left: 34%;
            transform: translate(-50%, 100%);
            margin: 0;
            fill: $map-marker-color;
            > svg{
                width: 100%;
                height: auto;
            }
        }
    }
    &__aside{
        @include min(1024px){
            padding-top: 9.7vw;
        }
    }
    &__guide {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: rem(17px);
        letter-spacing: 2.25px;
        font-weight: 500;

        @include min(600px){
            left: 450px;
        }
        @include min(1024px){
            left: auto;
        }
        &__container{
            position: relative;
            height: 80px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            order: 10;
            z-index: 4;
            @include min(600px){
                height: 150px;
            }
        }

        &-text{
            white-space: nowrap;
        }
        &-icon {
            position: relative;
            fill: currentColor;
            transition: all .5s ease-out;
            fill: $guide-ico-bg-color;
            top: 5px;
        }
	}

	@include link(".header__guide") {
		color: #252525;
		text-decoration: underline;
	}

	@include link-over(".header__guide") {
        text-decoration: none;
		.header__guide-icon {
            top: 10px;
		}
	}
}

.social_networks{
    width: 100%;
    text-align: center;
    @include min(1024px){
        margin-top: auto;
    }
    &__heading{
        font-size: rem(30px);
        font-weight: 500;
        color: $contact-color;
    }
    &__container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
        background-color: $social-networks-bg-color;
    }
    &__link{
        margin: .5em 1em;
    }
    svg{
        width: 32px;
        height: 32px; 
        fill: $social-networks-color; 
    }
}

.home__section_container{
    position: relative;
    &__heading{
        position: relative;
        background-color: $main-aside-bg-color;
        &__title{
            padding: 0.5em 4%;
            color: $main-aside-color;
            text-align: center;
        }
    }
    @include min(1024px){
        &__heading{
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            bottom: 0;
            //width: 234px;
            width: 12.3%;
            display: flex;
            align-items: center;
            justify-content: center;
            &__title{
                transform: rotate(-90deg);
                margin: 0;
                white-space: nowrap;
            }
        }
        .centered{
            max-width: calc(100% - 225px);
            margin-left: 0;
            padding-left: 16.3%;
        }
    }
    @include min(1280px){
        .centered{
            max-width: calc(100% - 270px);
        }
    }
}

#news-heading,
#board-heading{
    text-align: center;
    font-size: rem(44px);
}
#board-heading{
    margin-top: 0.75em;
    margin-bottom: 0.6em;
    color: $board-heading-color;
}
.section.-news {
    position: relative;
    background-color: #f3f3f3;
    h2{
        color: $news-heading-color;
    }
    &::before,
    &::after{
        position: absolute;
        content: "";
        transform: skew(-16deg); 
        bottom: 0;
        width: 59%;
        height: 10px;
    }
    &::before{
        left: -10%;
        background-color: $news-left-line-bg-color;
    }
    &::after{
        right: -10%;
        background-color: $news-right-line-bg-color;
    }
}

.news {
    .list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        &__item{
            @include grid(600px 2, 800px 3);
            margin: 0 0 0;
        }
        &:not(.-no-spacing) {
			@include bp("> 600px") {
				margin-left: -$grid-spacing*.75;
				margin-right: -$grid-spacing*.75;

				.list__item {
					padding: $grid-spacing*.75;
				}
			}
        }
    }
    @include min(800px){
        .list__item{
            padding-top: 0!important;
        }
    }
}

.site_board {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;
		margin: 0 0 3em;
        overflow: hidden;
        backdrop-filter: blur(5px);
    }
    &__item {
        @include grid(650px 2);
        flex-grow: 0;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        > :only-child {
            flex-grow: 1;
        }
    }

    .board-card {
        flex-grow: 1;
    }
}