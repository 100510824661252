
//Colors

$header-bg-color: #0b2649;
$header-color: #ffffff;

$main-nav-bg-color: #ffffff;
$main-nav-color: #ffffff;
$main-nav-button-bg: #c9180a;
$main-nav-button-color-hover: #ffffff;
$main-nav-button-bg-hover: #103669;

$search-color: #222322;
$search-button-bg-color:#103669;

$blender-arrow-color: #103669;

$map-marker-color: #c9180a;

$contact-bg-color: #103669;
$contact-color: #ffffff;
$more-button-color: $contact-color;
$more-button-bg-color: $contact-bg-color;
$more-button-border-color: $contact-color;

$social-networks-bg-color: #0b2649;
$social-networks-color: #ffffff;

$guide-ico-bg-color: #c9180a;

$color-h2: #103669;
$color-h3: #103669;

$color-anchors: #c9180a;
$color-anchors-over: #103669;

$main-aside-bg-color: #c9180a;
$main-aside-color: #ffffff;

$news-bg: #f3f3f3;
$news-heading-color: $color-h2;
$news-date-color: #103669;
$news-calendar-color: #c6c6c6;
$news-date-color-hover: #c9180a;
$news-calendar-color-hover: #c9180a;
$news-title-color: #1d1d1d;
$news-title-color-hover: #c9180a;
$news-color: #1d1d1d;
$news-left-line-bg-color: #103669;
$news-right-line-bg-color: #c9180a;

$events-heading-color: $color-h2;
$events-calendar-color: #d3d3d3;
$events-date-color: #103669;
$events-date-slash-color: #103669;
$events-title-color: #1d1d1d;
$events-color: #1d1d1d;
$events-line-bg-color: #d3d3d3;
$events-calendar-color-hover: #c9180a;
$events-date-color-hover: #c9180a;
$events-date-slash-color-hover: #c9180a;
$events-title-color-hover: #c9180a;
$events-line-bg-color-hover: #c9180a;

$board-heading-color: #ffffff;
$board-blur-bg: #000000;
$board-color: #ffffff;
$board-calendar-from-color: #5b6365;
$board-more-bg-color: #ffffff;
$board-more-color: #1d1d1d;

$breadcrumbs-bg-color: $main-nav-bg-color;
$breadcrumbs-color: $search-color;
$breadcrumbs-color-active: $main-nav-button-bg;

$icon-links-bg-color: #ffffff;
$icon-links-title-color: #c9180a;
$icon-links-color: #1d1d1d;
$icon-links-color-hover: #1d1d1d;
$icon-links-ico-color: #103669;
$icon-links-ico-color-hover: #c9180a;

$additional-bg-color: #103669;
$additional-color: #ffffff;

$contact-form-input-color: #ffffff;
$contact-form-input-bg-color: #0b2649;
$contact-form-submit-color: #ffffff;
$contact-form-submit-bg-color: #103669;
$contact-form-submit-border-color: #ffffff;

$footer-bg-color: #0b2649;
$footer-color: #ffffff;

