
.blender {
    @include fill;
    z-index: 2; 
    
    &__container{
        width: 100%;
        height: 0;
        position: relative;
        overflow: hidden;
        background: $color-dark url(#{$img-loc}/blender/v-202311221240/0.jpg) no-repeat 50% 50% / cover;
        display: none;
        @include min(600px){
            display: block;
        }
    }

	&__buttons {
		position: absolute;
		z-index: 3;
		right: 0;
        top: 0;
        display: flex;
        flex-direction: column-reverse;
	}

	&__button {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
        box-shadow: none;
        width: 75px;
        height: 75px;
        cursor: pointer;
        &:first-of-type{
            background-color: #dddddd;
        }
        background-color: #bfbfbf;
		&:focus {
			box-shadow: none;
        }
        fill: $blender-arrow-color;
        @include min(1280px){
            width: 100px;
            height: 100px;
        }
	}


	@keyframes blend-in {
		from {
			display: block;
			opacity: 0;
		} to {
			display: block;
			opacity: 1;
		}
	}

	&__item {
		@include fill;
		background: {
			position: 50% 50%;
			repeat: no-repeat;
			size: cover;
		}

		&.-animating {
			animation: blend-in 800ms ease-out;
			animation-fill-mode: forwards;
		}
	}

	&__title,
	&__description {
		position: absolute;
		right: 0em;
        bottom: 4%;
		z-index: 51;
    }
    &__description{
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 35.8em;
        padding: 1rem 1em 1rem 6%;
        max-width: 55%;
        height: 140px;
        @include link{
            color: #ffffff;
            font-size: rem(21px);
            font-weight: 600;
            font-family: $font-headings;
            margin: 0 0 .25em;
            text-decoration: underline;
        }
        @include link-over{
            text-decoration: none;
        }
        strong{
            font-size: rem(21px);
            font-weight: 600;
            font-family: $font-headings;
            margin: 0 0 .25em;
        }
        span{
            font-size: rem(16px);
        }
        &:before{
            position: absolute;
            content: "";
            top: 0px;
            bottom: 0px;
            left: 0;
            right: -40px;
            background-color: rgba(#000000, .75);
            transform: skew(-14.5deg);
            z-index: -1;
        }
    }
}
