.warning{
    position: relative;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    width: 100%;
    z-index: 35;
    .text-component{
        position: relative;
        padding: 2rem 1em 2rem 10%;
        //min-height: 134px;
        min-height: 110px;
        width: 100%;
        overflow: visible;
        &:before{
            position: absolute;
            content: "";
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            background-color: rgba(#000000, .75);
            z-index: -1;
        }
    }
    @include min(600px){
        position: absolute;
        right: 0em;
        bottom: 7%;
        z-index: 51;
        max-width: 55%;
        width: 35.8em;
        
        .text-component{
            &:before{
                transform: skew(-14.5deg);
                right: -40px;
            }
        }
    }
    &_content{
        &__heading{
            font-size: rem(21px);
            font-weight: 600;
            font-family: $font-headings;
            margin: 0 0 .25em;
            color: #fff;
        }
        &__text{
            font-size: rem(16px);
        }
        @include link{
            color: #ffffff;
            font-size: rem(21px);
            font-weight: 600;
            font-family: $font-headings;
            margin: 0 0 .25em;
            text-decoration: underline;
        }
        @include link-over{
            text-decoration: none;
        }
    }
}